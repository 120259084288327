<template>
    <div>
<SubHeader  :linkActiveName="$t('Messages')" />
<DoneAlert :msg="$t('Data has been updated')" :visible="ShowDoneAlert" />
<ErrorAlert :msg="errorMgs" :visible="ShowError" />
<TableSkeleton  v-if="!showTable" />
<b-container fluid  v-if="showTable" class=" mt-5" >
<ul class="list-inline text-left">
  <li class="list-inline-item text-capitalize m-2">{{$t('Total')+': '+ total}}</li>
  <li class="list-inline-item text-capitalize m-2">{{$t('Page')+': '+current_page+' / '+last_page}}</li>

   </ul>
    <b-table striped hover bordered responsive :items="items" :fields="fields">
       <template #cell(action)="row">
      <b-button variant="primary" :to="{ name: 'Message_edit',params: { id: row.item.id }  }">{{$t('View')}}</b-button>
    
    </template>
    <template #cell(created_at)="row">
    
    {{row.item.created_at | moment }}
    </template>
    <template #cell(updated_at)="row">
     
    {{row.item.updated_at | moment }}
    </template>

    </b-table>
   
     <nav aria-label="...">
        <ul class="pagination">
           <li class="page-item ">
            <a class="page-link" v-if="first_page_url" @click="navPages(first_page_url)" ><b-icon icon="chevron-bar-left"></b-icon></a>
          </li>
          <li class="page-item ">
            <a class="page-link" v-if="prev_page_url" @click="navPages(prev_page_url)" ><b-icon icon="chevron-compact-left"></b-icon></a>
          </li>
      
          <li class="page-item active" aria-current="page">
            <a class="page-link" >{{current_page}}</a>
          </li>
         
          <li class="page-item">
            <a class="page-link" v-if="next_page_url" @click="navPages(next_page_url)"><b-icon icon="chevron-compact-right"></b-icon></a>
          </li>
          <li class="page-item ">
            <a class="page-link" v-if="last_page_url" @click="navPages(last_page_url)" ><b-icon icon="chevron-bar-right"></b-icon></a>
          </li>
        </ul>
      </nav>
 
</b-container>
</div>
</template>


<script>
import SubHeader from '@/components/layout/SubHeader.vue'
import axios from 'axios'
import DoneAlert from '@/components/alerts/DoneAlert.vue'
import ErrorAlert from '@/components/alerts/ErrorAlert.vue'
import TableSkeleton from '@/components/skeletons/TableSkeleton.vue'
import moment from "moment";
export default {
 components:{
        SubHeader, DoneAlert,TableSkeleton,ErrorAlert
        
    },
      /*********************** */
    data() {
      return {
         moment: function () {
        return moment();
        },
        form: {
            name: null,
            last_name: null,
            email: null,
            phone:null,
            password: null,
            confirm_password:null,
            status:null,
            image:null,
            role:null,
            lang_id:this.$i18n.locale,
            isSearch:null,
            date_from:null,
            date_to:null,
           

        },
        ShowDoneAlert:false,
        localeDate:'en-US',
        statuses: [ 'Read','‘Unread'],
        roles:[],
        errorsBack:{},
        current_page:null,
        last_page:null ,
        path:this.$store.state.backendUrl+"api/message",
        first_page_url:"",
        last_page_url:"",
        next_page_url:"",
        prev_page_url:"",
        per_page:null,
        total:null,
        showTable: false,
        isSendingForm:false,
        errorMgs:'',
        ShowError: false,
        // Note 'isActive' is left out and will not appear in the rendered table
        fields: [
           {
            key: 'id',
            label: this.$t('ID'),
            sortable: true
          },
          {
            key: 'name',
            label: this.$t('Name'),
            sortable: true
          },
          {
            key: 'email',
            label: this.$t('Email'),
            sortable: true
          },
            {
            key: 'phone',
            label: this.$t('Phone'),
            sortable: true
          },
            {
            key: 'subject',
            label: this.$t('Subject'),
            sortable: true
          },
            {
            key: 'message',
            label: this.$t('Message'),
            sortable: true
          },
           {
            key: 'status',
            label: this.$t('Status'),
            sortable: true
          },
           {
            key: 'created_at',
            label: this.$t('Created_at'),
            sortable: true
          },
          {
            key: 'updated_at',
            label: this.$t('Updated at'),
            sortable: true
          },
          {
            key: 'action',
            label: this.$t('Action'),
            sortable: false
          },
  
        ],
        items: []
      }
    },
    /******************* */
    filters: {
      moment: function (date) {
        return moment(date).format('MMMM Do YYYY, h:mm:ss a');
      }
      },
     /*********************** */

      methods: {
       fixingOption(json){
          var array=[];
          for (let index = 0; index < json.length; index++) {
            array[index] ={"text":this.$t(json[index].name),"value":json[index].name} ;
            
          }
           return array;
        },
         getCreateData(){
           this.showTable= false
           axios.get(this.path, {params:this.form,
            headers: {
           'Authorization': 'Bearer ' + this.$store.state.token,
            "Content-Type": "application/json",
            }
            })
            .then((response) => {
                console.log(response);
               
           
                 var rows = response.data.success.rows;
                 this.items = rows.data;
                 this.showTable = !this.showTable;
                  this.current_page=rows.current_page,
                  this.last_page=rows.last_page,
                  this.path=this.$store.state.backendUrl+"api/user",
                  this.first_page_url=rows.first_page_url,
                  this.last_page_url=rows.last_page_url,
                  this.next_page_url=rows.next_page_url,
                  this.prev_page_url=rows.prev_page_url,
                  this.per_page=rows.per_page,
                  this.total=rows.total,
                  this.showTable= true
                  this.isSendingForm = false;
         
            })
            .catch( (error) => {
                console.log(error);
                this.ShowError=true;
                this.errorMgs = error;
                
            });
           
      } , 
      navPages(PageURl){
        this.path = PageURl;
         this.getCreateData();
      },
      onSubmit(event) {
        event.preventDefault()
        if (!this.$v.$invalid) {

        this.isSendingForm = true;
        //alert(JSON.stringify(this.form))
        const formData = new FormData();
        for (const [key, value] of Object.entries(this.form)) {
             if(value != null){
               formData.append(key,value)
               console.log(key, value);
              }
        }
        this.getCreateData();

           }
      },
    },
    /***************** */
     mounted() {
      this.getCreateData();
      //this.form.lang_id = this.$i18n.locale;
      if(this.$i18n.locale == 'ar') {this.localeDate ='ar-EG'}
    }

}

</script>